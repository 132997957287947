.page-footer {
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  width: 100%;

  margin-top: 2.4rem;

  background-color: var(--dark-aluminium);

  color: var(--light-aluminium);
  font-weight: 500;
  text-align: center;

  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

.page-footer-grid {
  display: grid;
  grid-template-rows: auto auto;

  width: 100%;
  max-width: 120rem;

  /* responsive */

  padding: 1rem;
}

.page-footer-grid__info {
  display: grid;

  /* responsive */

  grid-template-columns: 1.5fr 1fr auto;
  column-gap: 2.4rem;
}

.page-footer-grid__brand {
  display: grid;

  justify-items: center;

  grid-template-columns: 1fr;
  margin-top: 1.2rem;
}

.page-footer-info__message {
  /* responsive */

  text-align: left;
}

.page-footer-message__headline {
  margin: 0 0 1.4rem;

  font-weight: 500;
}

.page-footer-message__description {
  margin-bottom: 0;

  letter-spacing: 0.016rem;
}

.page-footer-info__button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-footer-info__resource-list {
  display: grid;

  /* responsive */

  row-gap: 0.8rem;
}

.page-footer-info__resource-list-item {
  text-align: right;
}

.page-footer-brand {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.page-footer-brand__logo {

  margin-right: 12px;
}

.page-footer__hyperlink {
  letter-spacing: 0.001rem;
}

.page-footer__hyperlink,
.page-footer__hyperlink:active,
.page-footer__hyperlink:visited {
  color: var(--white);
}

.page-footer__hyperlink:hover {
  color: var(--aluminium);
}

@media only screen and (max-width: 960px) {
  .page-footer {
    /* responsive */

    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .page-footer-grid {
    /* responsive */

    padding: 1rem;
  }

  .page-footer-grid__info {
    /* responsive */

    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }

  .page-footer-info__message {
    /* responsive */

    text-align: center;
  }

  .page-footer-info__resource-list {
    /* responsive */

    grid-template-columns: 1fr 1fr;
    row-gap: 3.2rem;
    column-gap: 3.2rem;
  }

  .page-footer-info__resource-list-item:nth-child(odd) {
    text-align: right;
  }

  .page-footer-info__resource-list-item:nth-child(even) {
    text-align: left;
  }
}

@media only screen and (max-width: 480px) {
  .page-footer {
    /* responsive */

    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .page-footer-grid {
    /* responsive */

    padding: 1.2rem 1.6rem;
  }

  .page-footer-grid__info {
    /* responsive */

    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }

  .page-footer-info__message {
    /* responsive */

    text-align: center;
  }

  .page-footer-info__resource-list {
    /* responsive */

    grid-template-columns: 1fr 1fr;
    row-gap: 3.2rem;
    column-gap: 3.2rem;
  }

  .page-footer-info__resource-list-item:nth-child(odd) {
    text-align: right;
  }

  .page-footer-info__resource-list-item:nth-child(even) {
    text-align: left;
  }
}
