.terminal {
    background-color: #5e687e; /* Dark blue color */
    color: #f3f4f6;
    /* font-family: 'Consolas', 'Courier New', Courier, monospace;*/


    font-family: Optimistic Text, -apple-system, ui-sans-serif, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 1em;
    font-size: 1.5rem;


}

.terminal-header {
    background-color: #4e5769; /* Slightly lighter blue color */

    /* background-color: #4e5769; /* Slightly lighter blu*/
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.terminal-title {
    font-size: 1.6rem;
}

.copy-button-container {
    background-color: transparent;
    color: #f1f2f4;
    padding-left: 0.250rem;
    padding-right: 0.250rem;
    padding-top: 0.250rem;
    padding-bottom: 0.250rem;
    border-radius: 4px;
    border-radius: 0.25rem;
    cursor: pointer;


}

.copy-button-container:hover {
    background-color: #646F86;
    align-items: center;
    vertical-align: bottom;
    cursor: pointer;

}

.copy-button-container:active {
    background-color: #3f4756;
    align-items: center;
    vertical-align: bottom;
    cursor: pointer;

}

.terminal-body {
    flex-grow: 1;
    overflow-y: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    white-space: pre-line;
    font-size: 1.8rem;

}

.terminal-body code {
    color: #222222; /* Light grey color for the command */
}


.chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #23272f;
    justify-content: flex-end;
}

.chat-responses {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
}

.chat-input {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid gray;

    flex-direction: row;
    justify-content: space-between;

}

.chat-textarea {
    background-color: #353a46;
    border: 0px;
    border-radius: 4px;
    color: aliceblue;
    padding: 1.5rem;
    resize: none;
    width: 98%;
    font-size: 1.5rem;

}

.chat-textarea:active {
    background-color: #353a46;
    border: 0px;

}

.chat-textarea:focus {
    background-color: #353a46;
    outline: none;
    border: none;

}

.send-button-container {
    background-color: transparent;

}

.send-button {
    background-color: transparent;
    border: 0px solid red;
}

.send-button:hover {
    background-color: transparent;
    cursor: pointer;
}

.send-button:active {
    background-color: transparent;
    cursor: pointer;
}

.send-button:disabled {
    background-color: transparent;
    pointer-events: none;

}

.close {
    width: 5rem;
    height: 5rem;
    fill: whitesmoke;
    transform: rotate(45deg);
    transform-origin: 35% 20%;
    transform-box: fill-box;
    cursor: pointer;
}

.close-disabled {
    width: 5rem;
    height: 5rem;
    pointer-events: none;
    fill: rgba(245, 245, 245, 0.712);
    transform: rotate(45deg);
    transform-origin: 35% 20%;
    transform-box: fill-box;
}


.close:hover {
    fill: whitesmoke;
}

.close:active {
    fill: rgb(109, 106, 106);
}

.close:disabled {
    fill: rgba(245, 245, 245, 0.712);
}


/* api spinner */
.loader-sp {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 10px solid;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
}
@keyframes animloader {
    0% {
        border-color: white rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
    }
    33% {
        border-color: white white rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
    }
    66% {
        border-color: white white white rgba(255, 255, 255, 0);
    }
    100% {
        border-color: white white white white;
    }
}